<template>
<!--Button-->
<body >
  <div id="wrapper">
	<div id="bg"></div>
			<div id="overlay"></div>
			<div id="main">
				<!-- Header -->
					<header id="header">

						<h1><img class="logo" src="../src/images/YOSSNewLogo.png"/></h1>
						<p>Barbier</p>
						<p>Place : <a href="https://www.google.com/maps/place/448+Rue+Blainville+E,+Sainte-Th%C3%A9r%C3%A8se,+QC+J7E+1N9,+Canada/@45.643055,-73.816683,5z/data=!4m6!3m5!1s0x4cc927b8b8aa7a67:0x2fde7c022151f534!8m2!3d45.6430555!4d-73.8166828!16s%2Fg%2F11bw3zqw8p?hl=en-US&entry=ttu">Mallo Coiffure et Barbe</a></p>

						<nav>
							<ul>
								<li><a href="https://www.instagram.com/yoss.le.barbier/" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
								
								<li><a href="https://www.facebook.com/YossLeBarbier/" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
								
								<li> <a href="https://yosslebarbier.resurva.com/book" class="icon far fa-calendar-alt"><span class="label">Booking</span></a></li>
							
								<li> <a href="tel:4384027728" class="fas fa-phone"><span class="label">Téléphone</span></a></li>

							</ul>
						</nav>

						<p>Instagram &nbsp;&bull;&nbsp;FaceBook &nbsp;&bull;&nbsp; Rendez-Vous &nbsp;&bull;&nbsp; Téléphone</p>

            <p>Adresse : <a href="https://www.google.com/maps/place/Mallo+Coiffure+et+Barbe/@45.6429454,-73.819226,17z/data=!3m1!4b1!4m6!3m5!1s0x4cc923935e30d4d3:0x612683c4c4d64690!8m2!3d45.6429417!4d-73.8166511!16s%2Fg%2F11lkyrp38p?entry=ttu">448B Rue Blainville E, Sainte-Thérèse, QC J7E 1N9</a></p>
					</header>

			</div>
	</div>

<div id="761923527">
   
</div>

</body>


  
</template>


 <script type="text/javascript">
        try {
            window._mNHandle.queue.push(function (){
                window._mNDetails.loadTag("761923527", "728x90", "761923527");
            });
        }
        catch (error){
			// eslint-disable-next-line 
		}
    </script>